import React from 'react';
import { CLIENTS } from '../../../helpers/constants';

export const LIST_STATUSES = {
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled'
}

export const DOWNLOAD_OPTIONS = {
  [CLIENTS.CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.EU_CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.EP_CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.CAMS_CLEAROUT.NAME]: [
    {
      key: 'guaranteed_deliverable',
      label: 'Guaranteed Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.',
    },
    {
      key: 'guaranteed_deliverables_with_risk',
      label: 'Deliverables With Risk',
      immediateDownloadText: '(Immediate download)',
      description: (
        <div>
          <p className="mb-0">
            The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
            determined risky. The risk factor depends upon multiple reasons like
          </p>
          <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
            <li>Low deliverability score</li>
            <li>High volume of role-based email addresses</li>
            <li>Any temporary mail account issue</li>
            <li>Mail server configured to accept all email messages</li>
          </ul>
          <p className="mb-0">
            The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
            your own email sending platform without relying on an external Email Service Provider (ESP).
          </p>
        </div>
      ),
    },
    {
      key: 'non_deliverable',
      label: 'Non - Deliverables',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list.',
    },
    {
      key: 'email_with_std_co_col',
      label: 'Email addresses with Clearout standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.',
    },
    {
      key: 'custom',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by.',
    },
  ],
  [CLIENTS.CLEAROUTPHONE.NAME]: [
    {
      key: 'append_cop_std_col',
      label: 'Append with ClearoutPhone Standard columns',
      immediateDownloadText: '(Immediate download)',
      description:
        'The resulting file will include your original list together with the ClearoutPhone standard columns. Duplicate entries will not be part of the result file.',
    },
    {
      key: 'custom_clearoutphone',
      label: 'Custom',
      immediateDownloadText: '(Immediate download)',
      description:
        'The resulting file will include your original list together with the ClearoutPhone standard columns for the chosen statuses - Valid, Invalid, and Unknown. Select columns you want to filter by :',
    },
  ],
};

export const VALIDATION_FILTERS = {
  [CLIENTS.CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    catch_all: {
      value: 'catch_all',
      name: 'Catch All',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    valid: {
      value: 'valid',
      name: 'Valid',
    },
    invalid: {
      value: 'invalid',
      name: 'Invalid',
    },
    unknown: {
      value: 'unknown',
      name: 'Unknown',
    },
  },
};

export const COP_DOWNLOAD_FILE_APPEND_COLUMNS = ['status', 'carrier', 'country_code', 'country_name', 'country_timezone', 'e164_format', 'international_format', 'line_type', 'local_format', 'location', 'verified_on', 'time_taken']

export const DELIVERABLES_GUARANTEED =
  'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.';

export const DELIVERABLES =
  'The result file will include only the valid email addresses appended to your original list. Sending emails to these email addresses are completely safe.';

export const NON_DELIVERABLES_SAFE_TO_SEND =
  'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list';

export const DELIVERABLES_WITH_RISK =
  'The result file will include email addresses that are guaranteed to be delivered along with the email addresses that are determined as risky. The email address of this result file are safe to be used when bounce rate is strictly below 5% or running dedicated mail server with out dependence on external Email Service Provider(ESP).';

export const VALID_PHONE_NUMBER =
  'The resulting file will include only the valid phone numbers appended to your original list. These numbers are syntax free and reachable.';

export const INVALID_PHONE_NUMBER =
  'The resulting file will include only the Invalid phone numbers appended to your original list. These numbers won\'t be reachable and can be safely removed from the list.';

export const NON_DELIVERABLES =
  'The result file will include only the invalid email addresses appended to your original list. An email address will be marked \'invalid\' for many reasons. Avoid sending emails to these email addresses.';

export const PIECHART_RESULT_ORDER = {
  email_verifier: [
    'valid',
    'invalid',
    'catch_all',
    'unknown'
  ],
  phone_number_verifier: [
    'valid',
    'invalid',
    'unknown'
  ],
  email_finder: [
    'role',
    'business',
    'duplicate',
  ],
};

export const ALL_RESULT_METRICS = [
  'total',
  'valid',
  'invalid',
  'catch_all',
  'unknown',
  'duplicate',
  'syntax_error',
  'disposable',
  'gibberish',
  'free_account',
  'role_account',
  'business',
  'found',
  'total_phones',
  'mobile_phones',
  'fixed_phones',
  'others_phones',
  'unknown_phones',
  'valid_phones',
  'invalid_phones',
  'total_emails',
  'personal_emails',
  'work_emails',
  'valid_emails',
  'catch_all_emails',
  'invalid_emails',
  'unknown_emails',
];

export const ANALYSIS_TABLE_DISPLAY_ORDER = {
  email_verifier: [
    'duplicate',
    'syntax_error',
    'disposable',
    'gibberish',
    'free_account',
    'role_account',
    'profile'
  ],
  phone_number_verifier: [
    'unknown',
    'duplicate',
  ],
  email_finder: [
    'duplicate',
    'business',
  ],
  prospect: [
    'total_phones',
    'mobile_phones',
    'fixed_phones',
    'others_phones',
    'unknown_phones',
    'valid_phones',
    'invalid_phones',
  ]
};

export const TOP_RESULTS_TABLE_DISPLAY_ORDER = {
  email_verifier: [
    'total',
    'valid',
    'invalid',
    'catch_all',
    'unknown'
  ],
  phone_number_verifier: [
    'total',
    'valid',
    'invalid'
  ],
  email_finder: [
    'total',
    'found'
  ],
  prospect: [
    'total_emails',
    'personal_emails',
    'work_emails',
    'valid_emails',
    'catch_all_emails',
    'invalid_emails',
    'unknown_emails',
  ]
};

export const DELIVERABILITY_TABLE_DISPLAY_ORDER = {
  email_verifier: ['total', 'billable'],
  email_finder: ['total', 'billable', 'confidence_level'],
  phone_number_verifier: ['total', 'billable'],
  prospect: ['total']
}

export const INITIAL_FILTER_STATE = {
  email_finder: {
    date_range: {
      filter_label: 'Created On',
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 days (Including Today)'
    },
    processed: {
      filter_label: 'Status',
      label: 'In Progress',
      value: 'in_progress'
    }
  },
  email_verifier: {
    date_range: {
      filter_label: 'Created On',
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 days (Including Today)'
    },
    verified: {
      filter_label: 'Status',
      label: 'In Progress',
      value: 'in_progress'
    },
    type: {
      filter_label: 'Source',
      label: 'All',
      value: 'all'
    }
  },
  prospect: {
    date_range: {
      filter_label: 'Created On',
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 days (Including Today)'
    },
    enriched: {
      filter_label: 'Status',
      label: 'In Progress',
      value: 'in_progress'
    },
  },
  phone_number_verifier: {
    date_range: {
      filter_label: 'Created On',
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 days (Including Today)'
    },
    in_progress_status: {
      filter_label: 'Status',
      label: 'In Progress',
      value: 'in_progress'
    },
    type: {
      filter_label: 'Source',
      label: 'All',
      value: 'all'
    }
  },
}
